import React from "react";
import "./ChildSafety.css";

const ChildSafety = () => {
  return (
    <div className="child-safety-container">
      <h1>Çocuk Güvenliği Standartları</h1>

      <div className="child-safety-content">
        <p>
          Aloha Live, çocuk güvenliğini ciddiye alır ve platformumuzda güvenli
          bir ortam sağlamak için aşağıdaki önlemleri alır:
        </p>

        <section className="safety-section">
          <h2>1. İçerik Denetimi ve Moderasyon</h2>
          <ul>
            <li>
              Platformumuzda çocuk cinsel istismarı ve sömürüsü (CSAE), çocuk
              cinsel istismar materyalleri (CSAM), zorbalık, nefret söylemi ve
              uygunsuz içerikler kesinlikle yasaktır.
            </li>
            <li>
              İçerik moderasyon ekibimiz, şüpheli aktiviteleri tespit etmek ve
              engellemek için gelişmiş filtreleme sistemleri kullanmaktadır.
            </li>
            <li>
              Kullanıcılar, şüpheli veya uygunsuz içerikleri doğrudan uygulama
              içinden bildirebilirler.
            </li>
          </ul>
        </section>

        <section className="safety-section">
          <h2>2. Kullanıcı Doğrulama</h2>
          <ul>
            <li>
              18 yaş altı bireylerin uygulamamızı kullanmasını engellemek için
              ek güvenlik önlemleri uygulanmaktadır.
            </li>
            <li>Kullanıcı kaydı sırasında yaş tespiti yapılmaktadır.</li>
            <li>Şüpheli durumlarda ek doğrulama yöntemleri kullanılmaktadır.</li>
            <li>
              Google Play'de uygulamamız zaten 18 yaş ve üzeri olarak
              belirlenmiştir.
            </li>
          </ul>
        </section>

        <section className="safety-section">
          <h2>3. Bildirim ve İşbirliği</h2>
          <ul>
            <li>
              Çocuk istismarı veya yasa dışı içerik tespiti halinde, ulusal ve
              uluslararası yetkili makamlarla iş birliği yapmaktayız.
            </li>
            <li>
              Güvenlik konularında daha fazla bilgi almak için destek ekibimizle
              iletişime geçebilirsiniz:{" "}
              <a href="mailto:aloha@alohalive.online">aloha@alohalive.online</a>
            </li>
          </ul>
        </section>

        <section className="safety-section">
          <h2>4. Raporlama Mekanizmaları</h2>
          <ul>
            <li>
              Kullanıcılar, çocuk güvenliği ile ilgili endişelerini doğrudan
              uygulama içindeki "Şikayet" seçeneğini kullanarak rapor edebilir.
            </li>
            <li>
              Şikayet edilen içerikler, hızla değerlendirilerek gerekli önlemler
              alınmaktadır.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default ChildSafety;
